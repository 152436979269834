@import "../styles/variables.module";

.container {
  max-width: $content-max-width;
  max-width: 80%;
  padding: 1.6rem 3.2rem 9rem;
  margin: 0 auto;
  min-height: $content-min-height;
  color: $color-light;
  text-align: center;

  @include media(">desktop720p") {
    padding: 1.6rem 5rem 9rem;
  }

  @include media("<=tablet") {
    padding: 1.6rem;
  }
  
  h1 {
    font-size: 12rem;
    margin: 12rem 0 0;
  }
  h2 {
    font-size: 4.8rem;
    margin: 2rem 0 6rem;
  }
}